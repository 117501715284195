/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck

import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Configure,
  connectHighlight,
  connectInfiniteHits,
  InstantSearch,
} from 'react-instantsearch-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import InfiniteScroll from 'react-infinite-scroll-component';
import { convertSeconds } from '../stringTrim';
import ModeCommentOutlinedIcon from '@material-ui/icons/ModeCommentOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import ShareIcon from '@material-ui/icons/Share';
import GetAppIcon from '@material-ui/icons/GetApp';
import ErrorOutlineSharpIcon from '@material-ui/icons/ErrorOutlineSharp';
import Grid from '@material-ui/core/Grid';
import { InstabitIcon, ListenIcon, ReadIcon } from '../customElements/Buttons';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { searchClient } from '../app';
import queryString from 'query-string';
import { CustomWidthTooltip } from '../customElements/Buttons';
import { formatDistanceToNowStrict } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    color: '#575F6B',
    margin: '21px 0 14px',
  },
  postTitle: {
    display: '-webkit-box',
    lineClamp: 1,
    boxOrient: 'vertical',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 400,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 600,
    },
  },
  listWrapper: {
    borderRadius: 16,
    overflow: 'hidden',
    padding: 13,
    background: 'transparent linear-gradient(298deg, #F2F3F6 0%, #E5E6EC 100%)',
    boxShadow: '-4px -4px 20px #FFFFFF',
    maxWidth: 811,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: 15,
  },
  itemImage: {
    width: 78,
    height: 78,
    borderRadius: 5,
    minWidth: 78,
    overflow: 'hidden',
  },
  btnWrapper: {
    display: 'flex',
    marginLeft: 'auto',
    minWidth: 238,
    justifyContent: 'flex-end',
  },
  bottomBtn: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    position: 'relative',
    '& .MuiTypography-subtitle1': {
      color: '#575F6B',
      margin: '0px 10px',
    },
  },
  bottomBtnIcon: {
    fill: '#575F6B',
    fontSize: 21,
    textShadow: '0px 3px 6px #00000078',
  },
  postIcons: {
    paddingLeft: '4px',
    height: 11,
  },
  dividerMargin: {
    margin: '10px 20px 5px 0',
    position: 'absolute',
    bottom: -6,
    left: '0%',
    width: '90%',
  },
  TrimDesc: {
    display: '-webkit-box',
    lineClamp: 2,
    boxOrient: 'vertical',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 175,
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 250,
    },
    maxWidth: 390,
    fontSize: '14px',
  },
  topicWrapper: {
    overflowY: 'auto',
    // overflowX: 'hidden',
    height: '95%',
    '&::-webkit-scrollbar': {
      width: 8,
      borderRadius: 5,
      backgroundColor: '#95959512',
      overflow: 'hidden',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#9595956e',
      borderRadius: 5,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(149,149,149,0.7)',
    },
  },
  itemWrapper: {
    [theme?.breakpoints?.down('sm')]: {
      maxWidth: '100% !important',
    },
    width: '100%',
  },
  mobDateHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  desktopDateHide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const algoliaInsights = (hit) => {
  const profile = JSON.parse(localStorage.getItem('profile'));
  if (window?.aa && profile) {
    window.aa('clickedObjectIDsAfterSearch', {
      eventName: 'Post Clicked',
      userToken: profile?.sub?.replace('|', '-'),
      index: 'twimbit_master_enriched',
      objectIDs: [hit?.objectID],
      queryID: hit?.__queryID,
      positions: [hit?.__position],
      timestamp: Date.now(),
    });
  }
};

const Listens = connectInfiniteHits(
  ({
    hits,
    hasPrevious,
    refinePrevious,
    refineNext,
    hasMore,
    indexName,
    partnerSite,
    searchInput,
    type,
  }) => {
    const [searchAll, setSearchAll] = useState(false);
    const classes = useStyles();
    const theme = useTheme();
    const { site } = queryString.parse(window.location.search);
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const channelName = partnerSite ? 'this channel' : 'twimbit network';
    const pf = JSON.parse(localStorage.getItem('profile'));
    const titleLength = useMediaQuery(theme.breakpoints.up('md')) ? 65 : 45;
    const mappingWithTypes = {
      all: 'read',
      read: 'read',
      podcast: 'podcast',
      instareads: 'instareads',
    };
    if (hits?.length > 0) {
      return (
        <Box>
          <Box
            id={`search-scroll-${indexName}`}
            maxHeight={partnerSite ? 'auto' : matches ? 500 : 500}
            className={classes.topicWrapper}
          >
            {hits?.length ? (
              <Box>
                <InfiniteScroll
                  style={{ padding: '10px 0 20px 0', overflow: 'visible' }}
                  dataLength={hits.length ? hits.length : 0}
                  next={hasPrevious ? refinePrevious : refineNext}
                  hasMore={partnerSite ? false : hasMore || hasPrevious}
                  scrollableTarget={`search-scroll-${indexName}`}
                  loader={
                    partnerSite ? (
                      ''
                    ) : (
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        overflow="hidden"
                        height="50px"
                      >
                        <CircularProgress color="primary" />
                      </Box>
                    )
                  }
                  endMessage={
                    partnerSite ? (
                      ''
                    ) : (
                      <Typography
                        style={{
                          textAlign: 'center',
                          marginTop: 16,
                          color: 'grey',
                        }}
                      >
                        End of the results
                      </Typography>
                    )
                  }
                >
                  <Box>
                    {partnerSite ? (
                      <Typography
                        className={classes.title}
                        variant={'body1'}
                      >{`Results from ${channelName}`}</Typography>
                    ) : (
                      ''
                    )}
                    <Grid container>
                      {hits?.map((node) => {
                        const {
                          author,
                          link,
                          objectID,
                          date,
                          types,
                          title,
                          featuredImage,
                          instareads,

                          channel,
                        } = node;

                        const postImage = `${
                          featuredImage ?? instareads?.[0]?.url
                        }?tr=w-85,h-85`;
                        return (
                          <Grid
                            item
                            space={1}
                            key={objectID}
                            className={classes.itemWrapper}
                          >
                            <Link
                              onClick={(e) => algoliaInsights(node)}
                              href={link}
                              target={
                                link?.includes(
                                  process.env.NEXT_PUBLIC_REACT_APP_URI
                                )
                                  ? ''
                                  : '_blank'
                              }
                            >
                              <Box
                                height={'100%'}
                                position={'relative'}
                                color={'#000'}
                                display={'flex'}
                                maxWidth={'100%'}
                              >
                                <Box
                                  width={'100%'}
                                  display={'flex'}
                                  alignItems={'center'}
                                  maxWidth={'100%'}
                                  p={'10px 0px 10px 0'}
                                >
                                  <img
                                    src={
                                      postImage ??
                                      'https://twimbit.com/static/media/logo.7d441538.svg'
                                    }
                                    width="85px"
                                    height="85px"
                                    style={{
                                      marginLeft: 5,
                                      borderRadius: 10,
                                      boxShadow:
                                        'rgb(0 0 0 / 16%) 0px 3px 6px 3px',
                                    }}
                                    alt={title}
                                  />
                                  <Box
                                    flex="1"
                                    m="0 10px"
                                    maxWidth={'calc(100% - 100px)'}
                                    width={'calc(100% - 120px)'}
                                  >
                                    <Box
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Box style={{ width: '100%' }}>
                                        {title.length > titleLength ? (
                                          <CustomWidthTooltip
                                            title={title}
                                            placement="right"
                                            disableInteractive
                                          >
                                            <Typography
                                              variant="body1"
                                              gutterBottom
                                              style={{ fontSize: 19 }}
                                              className={classes.postTitle}
                                            >
                                              <CustomHighlight
                                                attribute={'title'}
                                                hit={node}
                                              />
                                            </Typography>
                                          </CustomWidthTooltip>
                                        ) : (
                                          <Typography
                                            variant="body1"
                                            gutterBottom
                                            style={{ fontSize: 19 }}
                                            className={classes.postTitle}
                                          >
                                            <CustomHighlight
                                              attribute={'title'}
                                              hit={node}
                                            />
                                          </Typography>
                                        )}
                                        <Typography
                                          noWrap={true}
                                          varinat="subtitle2"
                                          gutterBottom
                                          style={{
                                            fontSize: '16px',
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <Link
                                            href={
                                              link?.includes(
                                                process.env
                                                  .NEXT_PUBLIC_REACT_APP_URI
                                              )
                                                ? `/@${author?.[0]}/`
                                                : `https://twimbit.com/@${author?.[0]}/`
                                            }
                                            target={
                                              link?.includes(
                                                process.env
                                                  .NEXT_PUBLIC_REACT_APP_URI
                                              )
                                                ? ''
                                                : '_blank'
                                            }
                                            style={{ color: 'black' }}
                                          >
                                            <Typography noWrap={true}>
                                              @
                                              <CustomHighlight
                                                attribute={'author[0]'}
                                                hit={node}
                                              />
                                            </Typography>
                                          </Link>
                                          &nbsp;
                                          <span
                                            style={{
                                              color: 'rgba(0, 0, 0, 0.54)',
                                            }}
                                          >
                                            in
                                          </span>
                                          &nbsp;
                                          <Link
                                            href={
                                              link?.includes(
                                                process.env
                                                  .NEXT_PUBLIC_REACT_APP_URI
                                              )
                                                ? `/${channel?.name}/`
                                                : channel?.url
                                            }
                                            target={
                                              link?.includes(
                                                process.env
                                                  .NEXT_PUBLIC_REACT_APP_URI
                                              )
                                                ? ''
                                                : '_blank'
                                            }
                                            style={{ color: 'black' }}
                                          >
                                            <Typography noWrap={true}>
                                              <CustomHighlight
                                                attribute={'channel.name'}
                                                hit={node}
                                              />
                                            </Typography>
                                          </Link>
                                          &nbsp;&nbsp;
                                          {types?.map((node) => {
                                            if (node === 'read') {
                                              return (
                                                <ReadIcon
                                                  className={
                                                    (classes.postTypeIcon,
                                                    classes.postIcons)
                                                  }
                                                />
                                              );
                                            } else if (node === 'instareads') {
                                              return (
                                                <InstabitIcon
                                                  className={
                                                    (classes.postTypeIcon,
                                                    classes.postIcons)
                                                  }
                                                />
                                              );
                                            } else if (node === 'podcast') {
                                              return (
                                                <ListenIcon
                                                  className={
                                                    (classes.postTypeIcon,
                                                    classes.postIcons)
                                                  }
                                                />
                                              );
                                            }
                                          })}
                                          <Typography
                                            variant={'body1'}
                                            color="textSecondary"
                                            className={classes.desktopDateHide}
                                          >
                                            &nbsp;&middot;&nbsp;
                                            {formatDistanceToNowStrict(
                                              new Date(date),
                                              {
                                                addSuffix: true,
                                              }
                                            )}
                                          </Typography>
                                        </Typography>
                                      </Box>
                                      <Box style={{ width: '10%' }}>
                                        <More post={node} />
                                      </Box>
                                    </Box>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <Typography
                                        variant="subtitle1"
                                        color="textSecondary"
                                      >
                                        <Typography
                                          variant={'body1'}
                                          color="textSecondary"
                                          className={classes.mobDateHide}
                                        >
                                          {formatDistanceToNowStrict(
                                            new Date(date),
                                            {
                                              addSuffix: true,
                                            }
                                          )}
                                        </Typography>
                                        <CustomHighlight
                                          attribute={'content'}
                                          hit={node}
                                          snippet={true}
                                        />
                                      </Typography>
                                      {/*<Comment post={node}/>*/}
                                      {/*<Like post={node}/>*/}
                                      {/*<ListenBtn post={node} />*/}
                                    </Box>
                                  </Box>
                                </Box>

                                <Divider className={classes.dividerMargin} />
                              </Box>
                            </Link>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                </InfiniteScroll>
              </Box>
            ) : indexName === 'twimbit_posts_post' ? (
              <Typography
                variant="h3"
                color="textSecondary"
                align="center"
                style={{ marginBottom: 70 }}
              >
                We couldn’t find a match for your search.
                <br />
                <br />
                <a
                  href={'https://club.twimbit.com/'}
                  target={'_blank'}
                  rel={'noreferrer'}
                  style={{ color: '#EB6A6F' }}
                >
                  Ask in club
                </a>{' '}
                for more info.
              </Typography>
            ) : (
              ''
            )}
          </Box>
          {partnerSite ? <Divider style={{ marginTop: 21 }} /> : ''}
        </Box>
      );
    } else {
      if (partnerSite) {
        if (searchAll) {
          return (
            <InstantSearch
              searchClient={searchClient}
              indexName={'twimbit_master_enriched'}
            >
              <Configure
                clickAnalytics={true}
                analytics={true}
                attributesToSnippet={['content']}
                query={searchInput.length >= 2 && searchInput}
                userToken={pf?.sub?.replace('|', '-')}
                facets={['types']}
                facetFilters={`["types:${mappingWithTypes[type]}"]`}
                filters={
                  partnerSite
                    ? `NOT channel.type:"${
                        site
                          ? site.replace('https://', '')
                          : window.origin.replace('https://', '')
                      }"`
                    : ''
                }
              />
              <Listens />
            </InstantSearch>
          );
        } else {
          return (
            <Box m="30px 0" textAlign={'center'}>
              <Typography
                variant="h3"
                color="textSecondary"
                align="center"
                style={{ marginBottom: 10 }}
              >
                We couldn’t find a match for your search.
              </Typography>
              <Button
                variant={'text'}
                color={'primary'}
                onClick={() => setSearchAll(true)}
                style={{
                  textTransform: 'none',
                  fontSize: 15,
                  fontWeight: 400,
                }}
              >
                Search from all channels
              </Button>
            </Box>
          );
        }
      } else {
        return (
          <Box m="30px 0">
            <Typography
              variant="h3"
              color="textSecondary"
              align="center"
              style={{ marginBottom: 70 }}
            >
              We couldn’t find a match for your search.
              <br />
              <br />
              <a
                href={'https://club.twimbit.com/'}
                target={'_blank'}
                rel={'noreferrer'}
                style={{ color: '#EB6A6F' }}
              >
                Ask in club
              </a>{' '}
              for more info.
            </Typography>
          </Box>
        );
      }
    }
  }
);

export const CustomHighlight = connectHighlight(
  ({ highlight, attribute, hit, snippet }) => {
    const parsedHit = highlight({
      highlightProperty: snippet ? '_snippetResult' : '_highlightResult',
      attribute,
      hit,
    });
    return parsedHit?.map((part, index) =>
      part?.isHighlighted ? (
        <span style={{ color: '#EB6A6F' }} key={index}>
          {part.value}
        </span>
      ) : (
        <span key={index}>{part.value}</span>
      )
    );
  }
);

const ListenBtn = ({ post }) => {
  const classes = useStyles();
  // let {playNow} = useContext(PlayerContext);
  return (
    <Box className={classes.bottomBtn}>
      <a
        href={'#'}
        onClick={(e) => {
          // e.preventDefault();
          // playNow([post]);
        }}
      >
        {/*<Listen />*/}
      </a>
      <Typography variant={'subtitle1'} style={{ fontSize: 12 }}>
        {convertSeconds(post?.readcast?.length)}
      </Typography>
    </Box>
  );
};

const Comment = () => {
  const classes = useStyles();
  return (
    <Box className={classes.bottomBtn}>
      <ModeCommentOutlinedIcon className={classes.bottomBtnIcon} />
      <Typography variant={'subtitle1'} style={{ fontSize: 12 }}>
        266
      </Typography>
    </Box>
  );
};

const Like = () => {
  const classes = useStyles();
  return (
    <Box className={classes.bottomBtn}>
      <FavoriteBorderOutlinedIcon className={classes.bottomBtnIcon} />
      <Typography variant={'subtitle1'} style={{ fontSize: 12 }}>
        1.5k
      </Typography>
    </Box>
  );
};

const More = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  return (
    <Box className={classes.bottomBtn}>
      {/* <IconButton
        aria-label="more"
        aria-controls="more-menu"
        aria-haspopup="true"
        onClick={(e) => {
          e.preventDefault();
          // setAnchorEl(e.currentTarget);
        }}
      >
        <MoreVertIcon style={{ fill: "#575F6B", fontSize: "3rem" }} />
      </IconButton> */}
      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        keepMounted
        className={classes.moreItemsWrapper}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => setAnchorEl(null)}>
          <ListItemIcon className={classes.moreItemsIcon}>
            <PlaylistAddIcon style={{ fill: '#575F6B' }} />
          </ListItemIcon>
          <ListItemText primary="Add to" />
        </MenuItem>
        <MenuItem onClick={() => setAnchorEl(null)}>
          <ListItemIcon className={classes.moreItemsIcon}>
            <ShareIcon style={{ fill: '#575F6B' }} />
          </ListItemIcon>
          <ListItemText primary="Share" />
        </MenuItem>
        <MenuItem onClick={() => setAnchorEl(null)}>
          <ListItemIcon className={classes.moreItemsIcon}>
            <GetAppIcon style={{ fill: '#575F6B' }} />
          </ListItemIcon>
          <ListItemText primary="Download" />
        </MenuItem>
        <MenuItem onClick={() => setAnchorEl(null)}>
          <ListItemIcon className={classes.moreItemsIcon}>
            <ErrorOutlineSharpIcon style={{ fill: '#575F6B' }} />
          </ListItemIcon>
          <ListItemText primary="Report" />
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default Listens;
