export const stringTrim = (value) => {
  let lastIndex = value.split('/')
  return lastIndex[lastIndex.length - 3]
}

export const stringTrimMenuItems = (value) => {
  let lastIndex = value.split('/')
  return lastIndex[lastIndex.length - 2]
}

export const stringTrimSearch = (value) => {
  let lastIndex = value.split('/')
  return lastIndex[lastIndex.length - 1]
}

export const convertSeconds = (sec) => {
  let h = Math.floor(sec / 3600);
  let m = Math.floor((sec % 3600) / 60);
  let s = Math.floor(sec % 60);

  let hDisplay = h <= 0 ? "" : `${h}:`;
  let mDisplay = m < 10 ? `0${m}` : m;
  let sDisplay = s < 10 ? `0${s}` : s;

  return sec ? `${hDisplay}${mDisplay}:${sDisplay}` : "00:00";
};
