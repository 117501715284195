/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import MicIcon from '@material-ui/icons/Mic';
import SearchIcon from '@material-ui/icons/Search';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Search from './Search';
import {
  Configure,
  connectAutoComplete,
  connectHits,
  connectSearchBox,
  createVoiceSearchHelper,
  InstantSearch,
} from 'react-instantsearch-dom';
import VoiceAnimation from './voiceAnimation/VoiceAnimation';
import { Box, Chip, ClickAwayListener, Typography } from '@material-ui/core';
import { CloseIcon } from '../customElements/Buttons';
import logo from '../customElements/logo.svg';
import { searchClient } from '../app';
import { CustomHighlight } from './Listens';
import RestoreRoundedIcon from '@material-ui/icons/RestoreRounded';
import TrendingUpRoundedIcon from '@material-ui/icons/TrendingUpRounded';

let matches;

const useStyles = makeStyles((theme) => ({
  querySuggestionWrapper: {
    paddingLeft: '1.4rem',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
    '&:hover': {
      backgroundColor: '#d9d9db63 !important',
    },
  },
  querySuggestion: {
    display: '-webkit-box',
    lineClamp: 1,
    padding: '3px 20px !important',
    boxOrient: 'vertical',
    cursor: 'pointer',
    overflow: 'hidden',
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      padding: '3px 11px !important',
    },
    '&:hover': {
      backgroundColor: '#d9d9db63 !important',
    },
  },
  inputRoot: {
    color: 'grey !important',
    height: '90%  !important',
  },
  inputInput: {
    width: '100% !important',
    borderRadius: '50px !important',
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px) !important`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch !important',
      '&:focus': {
        width: '20ch !important',
      },
    },
    boxShadow:
      'inset 5px 5px 9px #f2f2f2, inset -5px -5px 9px #ffffff !important',
  },
  modal: {
    display: 'flex !important',
    alignItems: 'flex-start !important',
    justifyContent: 'center !important',
    margin: '30px !important',
  },
  modalMobile: {
    margin: '10px !important',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    flexBasis: '80% !important',
    overflow: 'auto !important',
    borderRadius: '10px !important',
    position: 'relative !important',
    maxHeight: 'calc(100vh - 40px) !important',
    boxShadow:
      '0 8px 22px 0 rgb(37,44,97,15%), 0 4px 6px 0 rgb(93,100,148,20%) !important',
    [theme.breakpoints.down('sm')]: {
      minHeight: '590px !important',
    },
    minHeight: '590px !important',
    display: 'flex',
    flexDirection: 'column',
  },
  paperMobile: {
    flexBasis: 'auto !important',
    padding: '10px !important',
    boxShadow: '-4px -4px 20px #FFFFFF',
    display: 'flex !important',
    flexDirection: 'column !important',
  },
  searchBar: {
    overflow: 'hidden !important',
    borderRadius: '83px  !important',
    background: '#ffffff !important',
    boxShadow: `inset 7px 7px 14px #ededed,
            inset -7px -7px 14px #ffffff !important`,
    border: 'none !important',
    width: '95%  !important',
    marginLeft: '10px !important',
    '&:hover': {
      boxShadow: `inset 7px 7px 14px #ededed, 0px 1px 12px rgba(0, 0, 0, 0.22) !important`,
    },
    '&:focus': {
      boxShadow: `inset 7px 7px 14px #ededed, 0px 1px 12px rgba(0, 0, 0, 0.22) !important`,
    },
    '&:focus-within': {
      boxShadow: `inset 7px 7px 14px #ededed, 0px 1px 12px rgba(0, 0, 0, 0.22) !important`,
    },
  },
  searchInput: {
    [theme.breakpoints.down('sm')]: {
      padding: '7px 0 0 16px !important',
    },
    padding: '7px 0 0 0 !important',
    width: '100% !important',
  },
  searchIcon: {
    marginLeft: '20px !important',
    color: 'grey !important',
    fontSize: '23px !important',
  },
  voiceButton: {
    width: '100% !important',
    borderRadius: '83px !important',
    padding: '10px 20px !important',
    color: '#ED6A6F !important',
    background: 'transparent !important',
    overflow: 'hidden !important',
  },
  closeButtonGrid: {
    textAlign: 'right !important',
  },
  closeButton: {
    borderRadius: '150px !important',
    background: 'linear-gradient(145deg, #e6e6e6, #ffffff) !important',
    boxShadow: `9px 9px 18px #d9d9d9`,
    padding: '6px !important',
  },
  searchInputLeftPadding: {
    padding: '7px 0 0 20px !important',
  },
  search: {},
  floatingBtn: {
    position: 'absolute',
    bottom: 0,
    zIndex: 1000,
    left: '44%',
  },
  suggestionWrapper: {
    overflowY: 'auto !important',
    overflowX: 'hidden !important',
    boxShadow: '1px 1px 24px #cdcdcd',
    '&::-webkit-scrollbar': {
      width: 8,
      borderRadius: 5,
      backgroundColor: '#95959512',
      overflow: 'hidden',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#9595956e',
      borderRadius: 5,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(149,149,149,0.7)',
    },
  },
  tagsSuggestion: {
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  widthSearch: {
    width: '722px !important',
  },
}));

export default function TransitionsModal() {
  const classes = useStyles();
  const theme = useTheme();
  matches = useMediaQuery(theme.breakpoints.up('sm'));

  const searchInputClasses = [classes?.searchInput, classes?.margin];
  const paperClasses = [classes.paper];
  const modalClasses = [classes.modal];
  const [voiceEnabled, setVoiceEnabled] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [open, setOpen] = React.useState(false);

  const [resultRefined, setResultRefined] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setResultRefined(false);
  };

  let placeholder = 'Search insights';

  if (!matches) {
    searchInputClasses.push(classes.searchInputLeftPadding);
    paperClasses.push(classes.paperMobile);
    modalClasses.push(classes.modalMobile);
    placeholder = 'Search insights, instabits...';
  }

  useEffect(() => {
    if (!open) return setSearchInput('');
  }, [open]);

  return (
    <Box>
      <Box displayPrint="none">
        <Box
          className={classes.search}
          display={'flex'}
          position={'relative'}
          alignItems={'center'}
        >
          <Paper
            component="div"
            className={(classes.root, classes.searchBar)}
            elevation={0}
          >
            <Grid
              container
              spacing={0}
              onClick={() => setOpen(true)}
              style={{ cursor: 'pointer' }}
            >
              <Grid
                item
                xs={1}
                sm={1}
                alignItems={'center'}
                direction={'row'}
                justify={'center'}
                style={{ display: 'flex' }}
              >
                <SearchIcon className={classes.searchIcon} />
              </Grid>
              <Grid
                item
                xs={9}
                sm={9}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Typography
                  noWrap={true}
                  onClick={() => setOpen(true)}
                  style={{
                    marginLeft: 16,
                    fontSize: 16,
                    cursor: 'pointer',
                    color: '#9DA0A7',
                    textAlign: 'left',
                    padding: 0,
                  }}
                >
                  {placeholder}
                </Typography>
              </Grid>
              <Grid xs={2} sm={2}>
                <IconButton
                  className={(classes.iconButton, classes.voiceButton)}
                  aria-label="search"
                  disabled={false}
                  onClick={() => setVoiceEnabled(true)}
                >
                  <MicIcon fontSize="large" />
                </IconButton>
              </Grid>
              <Divider className={classes.divider} orientation="vertical" />
            </Grid>
          </Paper>
        </Box>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={modalClasses.join(' ')}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={paperClasses.join(' ')}>
              {resultRefined ? (
                <>
                  <Grid container spacing={0}>
                    <Grid xs={10} sm={10} style={{ position: 'relative' }}>
                      <SearchBox
                        voiceEnabled={voiceEnabled}
                        setVoiceEnabled={setVoiceEnabled}
                        searchInput={searchInput}
                        setSearchInput={setSearchInput}
                        setResultRefined={setResultRefined}
                        resultRefined={resultRefined}
                      />
                    </Grid>
                    {matches ? (
                      <Grid xs={2} sm={2} className={classes.closeButtonGrid}>
                        <IconButton
                          className={(classes.iconButton, classes.closeButton)}
                          aria-label="close"
                          onClick={handleClose}
                        >
                          <CloseIcon style={{ fontSize: 30 }} />
                        </IconButton>
                      </Grid>
                    ) : (
                      <Grid
                        xs={2}
                        className={classes.closeButtonGrid}
                        id={'floatbtn'}
                      >
                        <IconButton
                          className={(classes.iconButton, classes.closeButton)}
                          aria-label="close"
                          onClick={handleClose}
                        >
                          <CloseIcon style={{ fontSize: 30 }} />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                  <div>
                    <Search searchInput={searchInput} />
                  </div>
                </>
              ) : (
                <>
                  <Box display={'flex'} justifyContent={'flex-end'} mb={3}>
                    <IconButton
                      className={(classes.iconButton, classes.closeButton)}
                      aria-label="close"
                      onClick={handleClose}
                    >
                      <CloseIcon style={{ fontSize: 30 }} />
                    </IconButton>
                  </Box>
                  <SearchBox
                    voiceEnabled={voiceEnabled}
                    setVoiceEnabled={setVoiceEnabled}
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    setResultRefined={setResultRefined}
                    resultRefined={resultRefined}
                  />
                  <InstantSearch
                    indexName={'twimbit_terms_post_tag'}
                    searchClient={searchClient}
                    refresh={true}
                  >
                    <Configure
                      hitsPerPage={7}
                      clickAnalytics={true}
                      analytics={true}
                    />
                    <TagsSuggestions
                      setSearchInput={setSearchInput}
                      setResultRefined={setResultRefined}
                    />
                  </InstantSearch>
                  <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                    alignItems={'center'}
                    mb={'0px'}
                    mt={'auto'}
                  >
                    <Typography variant={'body1'} style={{ display: 'flex' }}>
                      powered by &nbsp;
                      <img
                        style={{ marginTop: -5 }}
                        src={logo}
                        alt={'twimbit'}
                      />
                    </Typography>
                  </Box>
                </>
              )}
            </div>
          </Fade>
        </Modal>
      </Box>
    </Box>
  );
}

const SearchBox = connectSearchBox(
  ({
    currentRefinement,
    isSearchStalled,
    resultRefined,
    refine,
    voiceEnabled,
    setVoiceEnabled,
    searchInput,
    setSearchInput,
    setResultRefined,
  }) => {
    const handleEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
      refine(searchInput);
      return false;
    };
    const [voiceState, setVoiceState] = useState(undefined);
    const searchRef = useRef(null);
    const [suggestion, setSuggestion] = useState(false);
    const [listening, setListening] = useState(voiceEnabled);
    const [suggestionQuery, setSuggestionQuery] = useState(searchInput);
    const voiceSearchHelper = createVoiceSearchHelper({
      searchAsYouSpeak: true,
      onQueryChange: (q) => {
        setSearchInput(q);
        refine(q);
      },
      onStateChange: (e) => {
        setListening(voiceSearchHelper.isListening());
        setVoiceState(voiceSearchHelper.getState());
      },
    });
    const classes = useStyles();
    const searchInputClasses = [classes.searchInput, classes.margin];
    let placeholder = 'Search insights, instabits and experts';

    if (!matches) {
      searchInputClasses.push(classes.searchInputLeftPadding);
      placeholder = 'Search insights, instabits...';
    }
    const pf = JSON.parse(localStorage.getItem('profile'));
    const { toggleListening, isBrowserSupported, isListening } =
      voiceSearchHelper;
    useEffect(() => {
      searchRef.current.focus();
      if (voiceEnabled && !isListening()) {
        toggleListening();
      }
      return () => {
        voiceSearchHelper.dispose();
        setVoiceEnabled(false);
      };
    }, []);

    useEffect(() => {
      resultRefined ? setSuggestion(false) : searchRef.current.focus();
    }, []);

    return (
      <form onSubmit={handleEnter} method={'post'} style={{ width: '100%' }}>
        <ClickAwayListener onClickAway={(e) => setSuggestion(false)}>
          <Paper component="div" className={classes.root} elevation={0}>
            <>
              <SearchInput
                placeholder={placeholder}
                listening={listening}
                toggleListening={toggleListening}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                setResultRefined={setResultRefined}
                suggestionQuery={suggestionQuery}
                setSuggestionQuery={setSuggestionQuery}
                searchInputClasses={searchInputClasses}
                searchRef={searchRef}
                setSuggestion={setSuggestion}
                refine={refine}
                isBrowserSupported={isBrowserSupported}
              />
              <InstantSearch
                indexName="twimbit_posts_post_query_suggestions"
                searchClient={searchClient}
                refresh={true}
              >
                <Configure
                  userToken={pf?.sub?.replace('|', '-')}
                  clickAnalytics={true}
                  hitsPerPage={5}
                  analytics={true}
                />
                <SuggestionBox
                  defaultRefinement={suggestionQuery}
                  setSearchInput={setSearchInput}
                  refineResult={(q) => {
                    setSearchInput(q);
                    refine(q);
                    setSuggestion(false);
                    if (setResultRefined) {
                      setResultRefined(true);
                    }
                  }}
                  show={suggestion}
                  setSuggestion={setSuggestion}
                />
              </InstantSearch>
            </>
          </Paper>
        </ClickAwayListener>
      </form>
    );
  }
);

const SearchInput = ({
  searchInput,
  searchRef,
  setSuggestion,
  placeholder,
  searchInputClasses,
  refine,
  setSearchInput,
  suggestionQuery,
  setSuggestionQuery,
  isBrowserSupported,
  listening,
  toggleListening,
  setResultRefined,
}) => {
  const classes = useStyles();
  const [q, setQ] = useState(searchInput);
  useEffect(() => {
    setQ(searchInput);
  }, [searchInput]);
  return (
    <Grid container spacing={0} className={classes.searchBar}>
      <Grid
        item
        xs={1}
        sm={1}
        alignItems={'center'}
        direction={'row'}
        justify={'center'}
        style={{ display: 'flex' }}
      >
        <SearchIcon className={classes.searchIcon} />
      </Grid>
      <Grid item xs={9} sm={9}>
        <InputBase
          value={q}
          className={searchInputClasses.join(' ')}
          placeholder={placeholder}
          name={'search-input'}
          inputProps={{
            'aria-label': 'naked',
            'aria-autocomplete': 'none',
            autocomplete: 'off',
          }}
          style={{ fontSize: 16 }}
          inputRef={searchRef}
          onFocus={() => setSuggestion(true)}
          onKeyPress={(e) => {
            if (e?.key === 'Enter') {
              setSearchInput(q);
              refine(q);
              setSuggestion(false);
              if (setResultRefined) {
                setResultRefined(true);
              }
              const filteredRecentSearches =
                JSON.parse(localStorage.getItem('recent_search')) ?? [];
              if (!filteredRecentSearches.includes(q)) {
                filteredRecentSearches.push(q);
                localStorage.setItem(
                  'recent_search',
                  JSON.stringify(filteredRecentSearches)
                );
              }
            }
          }}
          // onBlur={(e) => {
          //   setSuggestion(false)
          // }}
          onChange={(e) => {
            // refine(e?.target?.value)
            // setSearchInput(e?.target?.value);
            setQ(e?.target?.value);
            setSuggestionQuery(e?.target?.value);
          }}
        />
      </Grid>
      <Grid xs={2} sm={2}>
        <IconButton
          className={(classes.iconButton, classes.voiceButton)}
          aria-label="search"
          disabled={!isBrowserSupported() || listening}
          onClick={() => {
            toggleListening();
          }}
        >
          {listening ? <VoiceAnimation /> : <MicIcon fontSize="large" />}
        </IconButton>
      </Grid>
    </Grid>
  );
};

const TagsSuggestions = connectHits(
  ({ hits, setSearchInput, setResultRefined }) => {
    const classes = useStyles();
    return (
      <Box
        display={'flex'}
        flexWrap={'nowrap'}
        maxWidth={'100%'}
        overflow={'hidden'}
        m={'30px 0px'}
        minHeight={32}
        className={classes.tagsSuggestion}
      >
        {hits?.map((item) => {
          return (
            <Chip
              key={item?.slug}
              label={`#${item?.slug}`}
              style={{
                fontSize: 16,
                borderRadius: 10,
                margin: '5px 0px 5px 10px',
                cursor: 'pointer',
                backgroundColor: '#e0e0e0',
              }}
              onClick={() => {
                setSearchInput(item?.slug);
                if (setResultRefined) {
                  setResultRefined(true);
                }
              }}
            />
          );
        })}
      </Box>
    );
  }
);

const SuggestionBox = connectAutoComplete(
  ({
    currentRefinement,
    refine,
    hits,
    refineResult,
    show,
    setSuggestion,
    setSearchInput,
  }) => {
    const classes = useStyles();
    let filteredRecentSearches =
      JSON.parse(localStorage.getItem('recent_search')) ?? [];
    if (currentRefinement?.length > 0) {
      filteredRecentSearches = filteredRecentSearches.filter((item) => {
        for (let k = 0; k < hits?.length; k++) {
          if (hits?.[k]?.query === item) {
            return false;
          }
        }
        return item.substr(0, currentRefinement?.length) === currentRefinement;
      });
    }
    if (!(hits?.length <= 0 && filteredRecentSearches?.length <= 0)) {
      return (
        <Box
          display={show ? 'block' : 'none'}
          position={'absolute'}
          zIndex={1}
          bgcolor={'#ffffff'}
          width={'93%'}
          pt={1}
          pb={1}
          mt={1}
          borderRadius={10}
          maxHeight={300}
          className={classes.suggestionWrapper}
        >
          {filteredRecentSearches?.slice(0, 3)?.map((hit: string, key) => {
            return (
              <Box
                key={key}
                display={'flex'}
                alignItems={'center'}
                className={classes.querySuggestionWrapper}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  refineResult(hit);
                  setSearchInput(hit);
                }}
              >
                <RestoreRoundedIcon className={classes.searchIcon} />
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  className={classes.querySuggestion}
                >
                  {hit}
                </Typography>
              </Box>
            );
          })}
          {hits?.map((hit) => {
            return (
              <Box
                key={hit?.objectID}
                display={'flex'}
                alignItems={'center'}
                className={classes.querySuggestionWrapper}
                onClick={(e) => {
                  const profile = JSON.parse(localStorage.getItem('profile'));
                  if (window?.aa && profile) {
                    window.aa('clickedObjectIDsAfterSearch', {
                      eventName: 'Query Suggestion Clicked',
                      userToken: profile?.sub?.replace('|', '-'),
                      index: 'twimbit_posts_post_query_suggestions',
                      objectIDs: [hit?.objectID],
                      queryID: hit?.__queryID,
                      positions: [hit?.__position],
                      timestamp: Date.now(),
                    });
                  }
                  e.preventDefault();
                  e.stopPropagation();
                  refineResult(hit?.query);
                  filteredRecentSearches.push(hit?.query);
                  localStorage.setItem(
                    'recent_search',
                    JSON.stringify(filteredRecentSearches)
                  );
                  setSuggestion(false);
                }}
              >
                <TrendingUpRoundedIcon className={classes.searchIcon} />
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  className={classes.querySuggestion}
                >
                  <CustomHighlight attribute={'query'} hit={hit} />
                </Typography>
              </Box>
            );
          })}
        </Box>
      );
    } else {
      return <></>;
    }
  }
);
