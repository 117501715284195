import React from 'react';
import styled from 'styled-components';
import { Box, SvgIcon, Tooltip, TooltipProps } from '@material-ui/core';

export const CurverdBox = styled(Box)`
  border-radius: 20px !important;
  font-size: 12px !important;
  padding: 0 0 0 !important;
  color: #575F6B !important;
  font-weight: 600;
  margin-bottom: 0;
`

export const SearchInput = styled.input`
  padding: 10px 20px;
  border-radius: 40px;
  border: none;
  background: #edeef2;
  box-shadow: inset 9px 9px 18px #d8d9dc, inset -9px -9px 18px #ffffff;
  color: #273850;
`



export const ReadIcon = (props) => (
  <SvgIcon width='13.243' height='11.063' viewBox='0 0 13.243 11.063' {...props}>
    <g transform='translate(0 0)'>
      <path d='M6,13.5H19.243v1.655H6Zm0,3.311h8.277v1.655H6Z' transform='translate(-6 -13.5)' fill='#9da0a7' />
      <path d='M6,13.5H19.243v1.655H6Zm0,3.311h8.277v1.655H6Z' transform='translate(-6 -7.403)' fill='#9da0a7' />
    </g>
  </SvgIcon>
)

export const ListenIcon = (props) => (
  <SvgIcon {...props} width='12.782' height='11.829' viewBox='0 0 12.782 11.829'>
    <path
      d='M3.994,9.01h-.4A1.647,1.647,0,0,0,2,10.7v1.684a1.647,1.647,0,0,0,1.6,1.693h.4a.824.824,0,0,0,.8-.847V9.856A.824.824,0,0,0,3.994,9.01Zm5.193,0h-.4a.824.824,0,0,0-.8.847v3.377a.824.824,0,0,0,.8.847h.4a1.647,1.647,0,0,0,1.6-1.693V10.7a1.647,1.647,0,0,0-1.6-1.693Zm-2.8-6.76C2.819,2.25.114,5.4,0,9.01v2.957a.411.411,0,0,0,.4.422H.8a.411.411,0,0,0,.4-.422V9.01A5.352,5.352,0,0,1,6.391,3.522,5.352,5.352,0,0,1,11.583,9.01v2.957a.411.411,0,0,0,.4.422h.4a.411.411,0,0,0,.4-.422V9.01C12.667,5.4,9.963,2.25,6.391,2.25Z'
      transform='translate(0 -2.25)' fill='#9da0a7' />
  </SvgIcon>
)

export const InstabitIcon = (props) => (
  <SvgIcon {...props} width='13.576' height='10.861' viewBox='0 0 13.576 10.861'>
    <path
      d='M17.158,10.125h-9.5a.908.908,0,0,0-.905.905v6.788a.908.908,0,0,0,.905.905h9.5a.908.908,0,0,0,.905-.905V11.03A.908.908,0,0,0,17.158,10.125Z'
      transform='translate(-4.487 -7.862)' fill='#9da0a7' />
    <path
      d='M3.5,5.971h9.39V5.405a.908.908,0,0,0-.905-.905H2.03a.908.908,0,0,0-.905.905v7.241a.908.908,0,0,0,.905.905H2.6V6.876A.908.908,0,0,1,3.5,5.971Z'
      transform='translate(-1.125 -4.5)' fill='#9da0a7' />
  </SvgIcon>
)



export const BurgerNav = (props) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <defs>
      <linearGradient
        id='linear-gradient-burger'
        x1='0.156'
        y1='0.283'
        x2='0.805'
        y2='0.778'
        gradientUnits='objectBoundingBox'
      >
        <stop offset='0' stop-color='#2c3036' />
        <stop offset='1' stop-color='#575f6b' />
      </linearGradient>
    </defs>
    <rect
      id='Rectangle_203'
      data-name='Rectangle 203'
      width='24'
      height='24'
      transform='translate(24 24) rotate(180)'
      opacity='0'
      fill='url(#linear-gradient-burger)'
    />
    <rect
      id='Rectangle_204'
      data-name='Rectangle 204'
      width='18'
      height='2'
      transform='translate(3 11)'
      fill='url(#linear-gradient-burger)'
    />
    <rect
      id='Rectangle_205'
      data-name='Rectangle 205'
      width='18'
      height='2'
      transform='translate(3 16)'
      fill='url(#linear-gradient-burger)'
    />
    <rect
      id='Rectangle_206'
      data-name='Rectangle 206'
      width='18'
      height='2'
      transform='translate(3 6)'
      fill='url(#linear-gradient-burger)'
    />
  </SvgIcon>
)



export const CloseIcon = props=>(
  <SvgIcon width="35.474" height="35.474" viewBox="0 0 35.474 35.474" {...props}>
    <defs>
      <linearGradient id="linear-gradient" x1="0.585" y1="0.629" x2="0.255" y2="0.051" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#575f6b"/>
        <stop offset="1" stop-color="#2c3036"/>
      </linearGradient>
      <filter id="Union_1" x="0" y="0" width="35.474" height="35.474" filterUnits="userSpaceOnUse">
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1.5" result="blur"/>
        <feFlood flood-color="#24415d" flood-opacity="0.298"/>
        <feComposite operator="in" in2="blur"/>
        <feComposite in="SourceGraphic"/>
      </filter>
    </defs>
    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_1)">
      <path id="Union_1-2" data-name="Union 1" d="M6.686,18.72V12.034H0V6.686H6.686V0h5.349V6.686H18.72v5.349H12.034V18.72Z" transform="translate(17.74 3.5) rotate(45)" fill="url(#linear-gradient)"/>
    </g>
  </SvgIcon>
)

export const CustomWidthTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)({
  [`& .MuiTooltip-tooltip`]: {
    color: '#575f6b',
    background: 'linear-gradient(#f2f3f6 0%,#eeeff5 100%)',
    boxShadow: '-4px -4px 20px #ffffff, 3px 3px 20px #24415d2e',
    marginTop: '5px',
    fontSize: '14px',
  },
});
