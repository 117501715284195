import {
  createGenerateClassName,
  StylesProvider,
} from '@material-ui/core/styles';
import algoliasearch from 'algoliasearch';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import { ThemeProvider } from '@material-ui/core';
import { theme } from './theme';
import SearchBox from './components/SearchBox';
import queryString from 'query-string';

const generateClassName = createGenerateClassName({
  productionPrefix: 'twimbitsearch',
});
export const searchClient = algoliasearch(
  '9SORV9YTZZ',
  '6dfface48df42d22e5a43a08915feb7e'
);
const { site } = queryString.parse(window.location.search);
const pf = JSON.parse(localStorage.getItem('profile') as string);
const partnerSite = site
  ? site !== 'twimbit.com' && site !== 'm.twimbit.com'
  : window.location.origin !== 'https://twimbit.com' &&
    window.location.origin !== 'https://m.twimbit.com';
const filters = partnerSite
  ? `channel.type:'${
      site
        ? site.replace('https://', '')
        : window.origin.replace('https://', '')
    }'`
  : '';

export function App() {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <InstantSearch
          indexName={'twimbit_master_enriched'}
          searchClient={searchClient}
          refresh={true}
        >
          <Configure
            clickAnalytics={true}
            analytics={true}
            attributesToSnippet={['content']}
            userToken={pf?.sub?.replace('|', '-')}
            filters={filters}
          />
          <SearchBox />
        </InstantSearch>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;
