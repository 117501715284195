import React from 'react';
import './VoiceAnimation.css';

const VoiceAnimation = () => {
    return (
        <div className="loader">
            <span class="stroke"></span>
            <span class="stroke"></span>
            <span class="stroke"></span>
            {/*<span class="stroke"></span>*/}
            {/*<span class="stroke"></span>*/}
            {/*<span class="stroke"></span>*/}
            {/*<span class="stroke"></span>*/}
        </div>
    )
}

export default VoiceAnimation;
