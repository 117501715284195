/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck
import React, { useEffect, useState } from 'react';
import { Box, makeStyles, Tab, Tabs } from '@material-ui/core';
import { Configure, connectRefinementList, Index } from 'react-instantsearch-dom';
import { CurverdBox } from '../customElements/Buttons';
import queryString from 'query-string';
import Instabits from './Instabits';
import Listens from './Listens';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    fontSize: '16px !important',
    fontWeight: '500 !important',
    textTransform: 'capitalize !important'
  },
  formControl: {
    margin: '2px 10px !important',
    minWidth: '12opx !important'
  },
  tabsRoot: {
    display: 'inline-block !important'
  },
  tabLabel: {
    fontSize: '1.5rem !important',
    textTransform: 'capitalize !important',
    margin: '0px !important',
    color: '#2C3036 !important',
    padding: '17px 15px !important'
  },
  selectEmpty: {
    marginTop: '8px !important'
  },
  barColor: {
    backgroundColor: '#EB6A6F !important'
  },
  toolbar: theme.mixins.toolbar,
  topicWrapper: {
    overflowY: 'auto',
    // overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: 8,
      borderRadius: 5,
      backgroundColor: '#95959512',
      overflow: 'hidden'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#9595956e',
      borderRadius: 5
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(149,149,149,0.7)'
    }
  }
}));

const tabs = [
  {
    title: 'All',
    slug: 'all',
    value: ''
  },
  {
    title: 'Instabits',
    slug: 'instareads',
    value: 'instareads'
  },
  {
    title: 'Listen',
    slug: 'podcast',
    value: 'podcast'
  }
];

// const Sorting = connectSortBy((props) => {
//   const classes = useStyles()
//   const [field, setField] = React.useState('DATE')
//   let { items, refine, currentRefinement } = props
//   return (
//     <Box>
//       <Typography
//         variant="h4"
//         color="textSecondary"
//         style={{ display: 'inline-block', margin: '18px 15px' }}
//       >
//         Sort:
//       </Typography>
//       <FormControl className={classes.formControl}>
//         <Select
//           labelId="field-select-label"
//           id="field-select"
//           value={currentRefinement}
//           displayEmpty
//           onChange={(e) => {
//             setField(e.target.value)
//           }}
//           inputProps={{ 'aria-label': 'Without label' }}
//           className={classes.selectEmpty}
//         >
//           {items?.map((item, key) => {
//             return (
//               <MenuItem
//                 value={item?.value}
//                 key={key}
//                 onClick={() => refine(item?.value)}
//               >
//                 {item?.label}
//               </MenuItem>
//             )
//           })}
//         </Select>
//       </FormControl>
//     </Box>
//   )
// })

const TabsMenu = connectRefinementList((props) => {
  const classes = useStyles();
  const { q: searchKey, filter } = queryString.parse(window.location.search);
  const { refine } = props;
  return (
    <>
      <Tabs
        value={props?.type}
        indicatorColor='primary'
        textColor='primary'
        aria-label='simple tabs example'
        onChange={(e, value) => props.setType(value)}
        classes={{ root: classes.tabsRoot }}
        style={{ flex: 1 }}
      >
        {tabs.map((item, index) => (
          <Tab
            label={item.title}
            classes={{ wrapper: classes.wrapper }}
            key={index}
            style={{ minWidth: '100px !important;' }}
            value={item.slug}
            onClick={() => {
              props.setType(item.slug);
              refine(item?.value);
            }}
          />
        ))}
      </Tabs>
    </>
  );
});

const Search = ({ searchInput }) => {
  const [type, setType] = useState('all');
  const classes = useStyles();
  const { site } = queryString.parse(window.location.search);
  const pf = JSON.parse(localStorage.getItem('profile'));
  const partnerSite = site ? (site !== 'twimbit.com' && site !== 'm.twimbit.com') : (window.location.origin !== 'https://twimbit.com' && window.location.origin !== 'https://m.twimbit.com');
  const filters = partnerSite
    ? `channel.type:'${site ? (site === 'twimbit.com' || site === 'm.twimbit.com') ? '' : site.replace('https://', '') : window.origin.replace('https://', '')}'`
    : '';

  useEffect(() => {
    sessionStorage.setItem('redirect_url', window.location.pathname);
  }, []);

  return (
    <Box m="auto" pl={0} pr={0}>
      <CurverdBox
        m="0 0"
        display="flex"
        // minWidth="676px"
        // maxWidth="70%"
        height="48px"
      >
        <TabsMenu
          attribute={'types'}
          setType={setType}
          type={type}
          defaultRefinement={['']}
        />
      </CurverdBox>
      <Divider />
      <Box
        maxHeight={'500px'}
        className={partnerSite ? classes.topicWrapper : ''}
      >
        <Index indexName={'twimbit_master_enriched'}>
          <Configure
            query={searchInput}
            clickAnalytics={true}
            analytics={true}
            attributesToSnippet={['content']}
            userToken={pf?.sub?.replace('|', '-')}
            filters={filters}
          />
          <Listens
            indexName={'my_posts_post'}
            partnerSite={partnerSite}
            searchInput={searchInput}
            type={type}
          />
        </Index>
      </Box>
    </Box>
  );
};

export default Search;
