import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
	shadows:["none"],
	spacing: 10,
	palette: {
		primary: {
			main: "#003AAC",
			light: "#D3EAFF"
		},
    secondary: {
      main: '#08243F',
      light: '#242424',
      dark: '#2C3036'
    },
    info:{
      main: '#F35665'
    },
		error: {
			main: "#8F8F8F"
		}
	},

	typography: {
		fontFamily:
			"'IBM Plex Sans', Nunito ,'Segoe UI',SegoeUI, SegoeUI-Bold -apple-system, BlinkMacSystemFont,Helvetica, sans-serif",
		h1: {
			fontSize: "3.5rem",
			fontWeight: 600
		},
		h2: {
			fontSize: "1.9rem"
		},
		h3: {
			fontSize: "1.7rem"
		},
		h4: {
			fontSize: "1.6rem",
			fontWeight: 600
		},

		body1: {
			fontSize: "1.5rem"
		},
		body2: {
			fontSize: "1rem"
		},
		subtitle1: {
			fontSize: "1.4rem"
		},
		subtitle2: {
			fontSize: "1.2rem"
		}
	},
overrides:{
		NestedMenuItem:{
			MuiButtonBase:{
				root:{
					marginLeft:'5%',
				}
			}
		},
		MuiMenuItem:{
			root:{
				whiteSpace: 'break-spaces',
			}

		},

		MuiAccordionSummary:{
			root:{
				backgroundColor:"#f8f8fc",
			},
		},
		MuiAccordionDetails:{
			root:{
				backgroundColor:"#f8f8fc",
				padding:"10px 32px 20px",
			},
		},
		MuiExpansionPanel:{
			root:{
				'&:before':{
					display:"none",
				},
			},
		}


},

});
